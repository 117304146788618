<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-Selective-VERSAFLUX-VERSAFLEX-1070509-900x675_663aa572bc.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
               <div class="col-xl-6">
              <br><br><br>
              <div class="row">
                <div class="col-2"></div>
                <div class="mt-1 col-6">
                  <h1 class="font-size-48 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                 <h2>VERSAFLUX Flux Module <br>
                      VERSAFLEX Soldering Module </h2>
                      <br>  <h5>
                    Individual adjustment of flux heads and solder pots in X/Y/Z. </h5>
                  </h2>
                </div>
                </div>
              </div>
             
            </div>
            <!-- end row -->
            <br /><br /><br />
            <div class="row">
            
              <div class="col-xl-6">
                <br /><br />
                <div class="mt-4">
                  <h1>Tech-Highlights VERSAFLUX VERSAFLEX</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h3>
                       Simultaneous fluxing and soldering on multi-layer boards in X- or Y-direction
                      </h3>
                    </li>
                    <br />
                    <li>
                      <h3>Highest flexibility coupled with shortest cycle time</h3>
                    </li>
                    <br />
                    <li>
                      <h3>Mixed production without downtime due to mechanical adjustments of the module</h3>
                    </li>
                    <br />
                    <li>
                      <h3>Automatic optimization of cycle time through CAD-Assistant 4</h3>
                    </li>
                    <br />
                    <li>
                      <h3>VERSAFLUX: Double supply with the same or alternative flux</h3>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
                <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/21csm_Ersa-Selective-SMARTFLOW-006-900x600_ae6f26d723.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              
            </div>


                <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>


            <div class="row">
              <div class="col-xl-12">
                <div class="">
                  <div class="">
                    <div class="row">
                      <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 500px; height: 300px"
                            title="YouToube Video"
                            src="https://www.youtube.com/embed/XCQSOhMcKrU" 
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                     <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 500px; height: 300px"
                            title="YouToube Video"  
                            src="https://www.youtube.com/embed/Fm7pWLOSqAY"  
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
          

            <br /><br />
          
           
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>